<template>
    <v-btn class="ma-2" :color="card.color" text-color="white" @click="$emit('click')" block x-small :style="{ textTransform: 'none' }">
        <strong>{{ item.operator }} </strong>
        <v-spacer />
        {{ card.msg }}
    </v-btn>
</template>

<script>

export default {
    props: {
        item: {
            type: Object,
        },
    },

    computed: {
        card() {
            let ret = { msg: "Erro", icon: "error", color: "red" };

            if (this.item.infoOnInsert) {
                if (this.item.infoOnInsert.data && this.item.infoOnInsert.data.last_transaction && this.item.infoOnInsert.data.last_transaction.card) { // pagarme
                    ret = { icon: "", color: "", msg: `${this.item.infoOnInsert.data.last_transaction.card.brand} (${this.item.infoOnInsert.data.last_transaction.card.last_four_digits})` }
                } else if (this.item.infoOnInsert.paymentMethod && this.item.infoOnInsert.paymentMethod.card) { // stripe
                    ret = { icon: "", color: "", msg: `${this.item.infoOnInsert.paymentMethod.card.brand} (${this.item.infoOnInsert.paymentMethod.card.last4})` }
                    if (this.item.infoOnInsert.paymentMethod.card.wallet) {
                        ret.msg += ` [${this.item.infoOnInsert.paymentMethod.card.wallet.type} - ${this.item.infoOnInsert.paymentMethod.card.wallet.dynamic_last4}]`;
                    }
                } else if (this.item.infoOnInsert.payment_method) { // mercado pago
                    if (this.item.infoOnInsert.payment_method == 'credit_card' && this.item.infoOnInsert.card) {
                        ret = { icon: "", color: "", msg: `${this.item.infoOnInsert.payment_method.id} (${this.item.infoOnInsert.card.last_four_digits})` }
                    } else {
                        ret = { icon: "warning", color: "warning", msg: `(${this.item.last4})` }
                    }
                }
            }
            return ret
        }
    }
};
</script>